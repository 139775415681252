import React from "react";
import ReactDOM from "react-dom";
import { UserProvider } from './UserContext'

import Name from "./NameComponent.js";
import Location from "./LocationComponent.js";
import Rank from "./RankComponent.js";
import FightStyle from "./FightStyleComponent.js";
import Height from "./HeightComponent.js";
import Weight from "./WeightComponent.js";
import Dob from "./DobComponent.js";
import Avatar from "./AvatarComponent.js";
import Url from "./UrlComponent.js";

import UserForm from "./UserForm"
import AnnouncementInfoBar from "./AnnouncementInfoBar";
import FooterInfoBar from "./FooterInfoBar";
import "./styles.css";



/**
 * Our data comes from users-data.js
 * -----------------------------
 */
import users from "./users-data";

/**
 * Our data
 * ------------------------
 */
// const user = {
//   name: "Ryu",
//   location: "Japan",
//   fightingStyle: "Ansatsuken",
//   dob: '7-21-1964',
//   occupation: "Wandering Martial Artist",
//   height: "175 cm", 
//   weight: "85 kg",
//   avatar:
//     "https://vignette.wikia.nocookie.net/streetfighter/images/b/bb/Ryu-gem.gif/revision/latest?cb=20100812031517"
// };

/**
 * Our React component where we should display data
 * ------------------------
 */
function App() {

  return (
    <div className="App">
      <div className="formWrap">
      <UserForm />
      {/* <div>
      <label for="">Name</label>
      <input className="inp_name" type="text" placeholder="Your Name" onChange={e => setName(e.target.value)}/>
      </div> */}
      
      </div>
      {/* Show user data here */}
      <div className="user-deets">
        
      </div>
      <div className="user-deets">
        <div>
            <Avatar />
            <Name />
            <Rank />
            <Location />
            <FightStyle />
            <Dob />
            <Height />
            <Weight />
            <Url />
          </div>
        {/* Display each data in array in a card */}
        {/* Each card must have a 'key' attribute */}
        {users.map((user, index) => {
          return (
            <div key={index}>
              <img src={user.avatar} alt={user.name} />
              <h3>
                <a href="https://google.com" >{user.name}</a>
              </h3>
              <p>
                <strong>Ranked</strong> 
                #{user.rank}
              </p>
              <p>
                <strong>Location</strong>
                {user.location}
              </p>
              <p>
                <strong>Fighting Style</strong>
                {user.fightingStyle}
              </p>
              <p>
                <strong>Date of Birth</strong>
                {user.dob}
              </p>
              <p>
                <strong>Height</strong>
                {user.height}
              </p>
              <p>
                <strong>Weight</strong>
                {user.weight}
              </p>
              <p>
                <strong>Fandom</strong>
                <a href="https://google.com" target="_blank" rel="noopener noreferrer">@{user.name}</a>
              </p>
            </div>
          )
        })}
      </div>
      

      
      <AnnouncementInfoBar />
      <FooterInfoBar />
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(
  <UserProvider>
    <App />
  </UserProvider>, rootElement
);
