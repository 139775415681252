// Data export
export default [
  {
    name: "Ryu",
    rank: 1,
    location: "Japan",
    fightingStyle: "Ansatsuken",
    dob: '7-21-1964',
    occupation: "Wandering Martial Artist",
    height: "175 cm", 
    weight: "85 kg",
    avatar:
      "https://vignette.wikia.nocookie.net/streetfighter/images/6/65/Character_Select_Ryu_by_UdonCrew.jpg/revision/latest/scale-to-width-down/165?cb=20091113143945"
  },
  {
    name: "Chun-Li",
    rank: 6,
    location: "China",
    fightingStyle: "Chinese martial arts",
    dob: '3-1-1968',
    occupation: "INTERPOL officer",
    height: "165 cm", 
    weight: "secret",
    avatar:
      "https://vignette.wikia.nocookie.net/streetfighter/images/0/03/Character_Select_Chun_Li_by_UdonCrew.jpg/revision/latest/scale-to-width-down/165?cb=20091113143916"
  },
  {
    name: "Sagat ",
    rank: 3,
    location: "Thailand",
    fightingStyle: "Muay Thai",
    dob: '7-2-1955',
    occupation: "Emperor of Muay Thai",
    height: "226 cm", 
    weight: "98 kg",
    avatar:
      "https://vignette.wikia.nocookie.net/streetfighter/images/d/d9/Character_Select_Sagat_by_UdonCrew.jpg/revision/latest/scale-to-width-down/165?cb=20091113010442"
  },
];
