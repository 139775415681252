import React, { useContext } from "react"
import { UserContext } from './UserContext'

const Total = () => {
    const user = useContext(UserContext)
    return(
            <p>
                <strong>Ranked</strong>
                #{user.total}
            </p>
    )
}

export default Total
