import React, { useContext } from "react"
import { UserContext } from './UserContext'

const Dob = () => {
    const user = useContext(UserContext)
    if (user.dob){
        return(
            <p>
                <strong>Date of Birth</strong>
                {user.dob}
            </p>
        )
    }
    return null
}

export default Dob
