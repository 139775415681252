import React , { createContext, useState } from "react"

export const UserContext = createContext();


export const UserProvider = ({children}) => {

    // const [name, setName] = useState('william')
    // const [location, setLocation] = useState('mars')
  
  const [name,setName] = useState("brucey")
  const [location,setLocation] = useState("lifted forest")
  const [fightStyle,setFightStyle] = useState("shogun style")
  const [height,setHeight] = useState("5ft 9in")
  const [weight,setWeight] = useState("185lbs")
  const [dob,setDob] = useState('5-15-1980')
  const [url,setUrl] = useState('')
  const [avatar,setAvatar] = useState("https://vignette.wikia.nocookie.net/streetfighter/images/7/71/Character_Select_Fei_Long_by_UdonCrew.jpg/revision/latest/scale-to-width-down/165?cb=20091113115557")
 
  const [num1, setNum1] = useState(2)
  const [num2, setNum2] = useState(3)
  const [total, setTotal] = useState(num1 + num2)

    return(

        <UserContext.Provider
            value={{
                name,setName,
                location,setLocation,
                fightStyle,setFightStyle,
                height,setHeight,
                weight,setWeight,
                dob,setDob,
                url,setUrl,
                avatar,setAvatar,
                num1, setNum1,
                num2, setNum2,
                total, setTotal
            }}
        >
            { children }
        </UserContext.Provider>
    )
}