import React, { useContext } from "react"
import { UserContext } from './UserContext'

const Weight = () => {
    const user = useContext(UserContext)
    return(

            <p>
                <strong>Weight</strong>
                {user.weight}
            </p>
        
    )
}

export default Weight
