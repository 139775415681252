import React from 'react';

export default function FooterInfoBar() {
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '0',
        left: '0',
        right: '0',
        fontSize: '14px',
        padding: '20px 10px',
        background: '#766754',
        color: '#bbb',
        letterSpacing: '0.5px'
      }}
    >
      Powered by some StreetFighterII{' '}
      <a
        href="https://streetfighter.fandom.com/wiki/Street_Fighter_II:_The_World_Warrior"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: '#40A8F3',
          textDecoration: 'none',
          display: 'inline-block',
          padding: '0 0 3px',
          borderBottom: '2px solid #40A8F3'
        }}
      >
        Fandom!
      </a>
    </div>
  );
}
