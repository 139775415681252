import React, { useContext } from "react"
import { UserContext } from './UserContext'

const Avatar = () => {
    const user = useContext(UserContext)
    return(
       
            <img src={user.avatar} alt={user.name} />
       
    )


}

export default Avatar
