import React, { useContext } from "react"
import { UserContext } from './UserContext'

const Height = () => {
    const user = useContext(UserContext)
    return(
    
            <p>
                <strong>Height</strong>
                {user.height}
            </p>
      
    )
}

export default Height
