import React, { useContext } from "react"
import { UserContext } from "./UserContext"

const Name = () => {
    const user = useContext(UserContext);
    const url = `https://streetfighter.fandom.com/wiki/${user.name}`
    return(
        <h3>
            <a href={url}>{ user.name }</a>
        </h3>
    );
}; 

export default Name;