import React, { useContext } from "react"
import { UserContext } from './UserContext'

const FightStyle = () => {
    const user = useContext(UserContext)
    return(
        
            <p>
                <strong>Fighting Style</strong>
                {user.fightStyle}
            </p>
        
    )
}

export default FightStyle
