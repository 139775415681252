import React, { useContext } from "react"
import { UserContext } from './UserContext'

const Url = () => {
    const user = useContext(UserContext)
    return(
      
        <p>
            <strong>Fandom</strong>
            <a href="https://vignette.wikia.nocookie.net/streetfighter/images/f/fd/Fei_Battle.png/revision/latest/scale-to-width-down/185?cb=20181128170933" target="_blank" rel="noopener noreferrer">@{user.name}</a>
        </p>
        
    )
}

export default Url
