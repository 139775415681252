import React, { useContext } from 'react'
import { UserContext } from './UserContext'

const Form = () => {

    const user = useContext(UserContext)
    
    function addThemTogether(){
        user.setTotal(user.num1 + user.num2)
    }
    return(
        <div className="user-form">
            <div><label>Your Name</label>
                <input 
                    className="inp_name" 
                    type="text" 
                    placeholder="Bruce Lee" 
                    onChange={e => user.setName(e.target.value)}/>
            </div>
            <div><label>Your Location</label>
                <input 
                    className="inp_location" 
                    type="text" 
                    placeholder="Your Location" 
                    onChange={e => user.setLocation(e.target.value)}/>
            </div>
            <div><label>Fighting Style</label>
                <input 
                    className="inp_fight_style" 
                    type="text" 
                    placeholder="Fighting Style" 
                    onChange={e => user.setFightStyle(e.target.value)}/>
            </div>
            <div><label>Height</label>
                <input 
                    className="inp_height" 
                    type="text" 
                    placeholder="Your Height" 
                    onChange={e => user.setHeight(e.target.value)}/>
            </div>
            <div><label>Weight</label>
                <input 
                    className="inp_weight" 
                    type="text" 
                    placeholder="Your Weight" 
                    onChange={e => user.setWeight(e.target.value)}/>
            </div>
            <div><label>DOB</label>
                <input 
                    className="inp_dob" 
                    type="text" 
                    placeholder="Date of Birth" 
                    onChange={e => user.setDob(e.target.value)}/>
            </div>
            <div><label>Losses</label>
                <input 
                    type="number" 
                    placeholder="Losses" 
                    onChange={e => user.setNum1(+e.target.value)} />
            </div>
            <div><label>Penalties</label>
                <input 
                    type="number" 
                    placeholder="Penalties" 
                    onChange={e => user.setNum2(+e.target.value)} />
            </div>
            
            <br />
            <button onClick={addThemTogether}>ADD DEEZ</button>
            
        </div>
    )



}

export default Form;